import { graphql } from "gatsby"
import Img from "gatsby-image"
import { withPreview } from "gatsby-source-prismic"
import React from "react"
import SEO from "../components/seo"
import SliceZone from "../components/sliceZone"

const PostTemplate = ({ data, location, pageContext: { locale } }) => {
  const post = data.prismicPost.data
  const title = post.title.text
  const metaTitle = post.meta_title ? post.meta_title : title
  return (
    <>
      <SEO title={metaTitle} description={post.meta_description} image={post.featured_image.fluid} />
      <div className="container">
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <Img fluid={post.featured_image.fluid}></Img>
          <header>
            <h1 itemProp="headline">{title}</h1>
            <p>{post.date}</p>
          </header>
          <section itemProp="articleBody">
            <SliceZone allSlices={post.body} />
          </section>
        </article>
      </div>
    </>
  )
}

export default withPreview(PostTemplate)

export const pageQuery = graphql`
  query PostBySlug($uid: String!, $locale: String!) {
    prismicPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      prismicId
      first_publication_date
      last_publication_date
      data {
        meta_title
        meta_description
        featured_image {
          fluid(maxHeight: 800) {
            ...GatsbyPrismicImageFluid
          }
        }
        title {
          text
        }
        date(formatString: "DD.MM.YYYY")
        categories {
          category {
            document {
              ... on PrismicCategory {
                data {
                  name
                }
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostBodyImage {
            slice_type
            id
            primary {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
